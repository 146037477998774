import { useRuntimeConfig } from "nuxt/app";
import { defineStore } from "pinia";
import { useLayoutStore } from "./layout.store";
import { useSettingsStore } from "./settings.store";
import helper from "../resources/js/libraries/helper";
import type { MetaAttributes } from "../types/meta-attributes.type";

export const useMetaStore = defineStore("meta", {
	state: () => ({
		meta: {
			title: "",
			description: "",
			canonical_url: "",
			prev_url: "",
			next_url: "",
			url: "",
			image: "",
			fb_app_id: "",
			appLanguages: [],
			href_langs: {},
			default_url: "",
		} as MetaAttributes,
	}),
	getters: {
		metaGetter: (state) => () => state.meta,
	},
	actions: {
		getMetaTags() {
			const config = useRuntimeConfig();
			const route = useRoute();

			const layoutStore = useLayoutStore();
			const settingsStore = useSettingsStore();
			const settings = settingsStore.settingsGetter();
			const languages = layoutStore.languagesGetter.filter(
				(l) => l.available_in_website === true
			);
			const href_langs = Object.fromEntries(languages.map((l) => (
				[
					[l.code],
					l.code.toLowerCase() == "en"
						? config.public.appUrl
						: helper.appendToUrl(config.public.appUrl, `${l.code}/`),
				]
			)));
			const default_meta: MetaAttributes = {
				url: new URL(route.fullPath, config.public.websiteUrl as string).href,
				appLanguages: languages,
				href_langs: href_langs,
				default_url: config.public.appUrl as string,
				image: new URL('logo.jpg', config.public.websiteUrl as string).href,
				fb_app_id: config.public.facebookAppId as string,
				title: settings.seo_meta_title,
				description: settings.seo_meta_description,
			};
			this.meta = default_meta;
		},

		// getMetaTag(key: string) {
		// 	return this.meta[key];
		// },

		setMeta(meta: MetaAttributes) {
			this.meta = meta;
		},
	},
});
